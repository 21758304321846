import {
  Banner,
  Box,
  ContactUsEmailLink,
  Container,
  Heading,
  Helmet,
  IconFamily,
  IconFosterCare,
  IconKinship,
  IconMaintenance,
  IconMentalHealth,
  IconRespiteAlt,
  Interfaces,
  Layout,
  PreStyledComponents,
  ReadMoreLink,
  Row,
  Section,
  Theme,
  Tile,
  routesObject
} from "@life-without-barriers/react-components"
import { ThemeProps, withTheme } from "styled-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const { IconWrapper } = PreStyledComponents
const { youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    careModelFactsheet: Interfaces.FileNode
  }
}

interface PrinciplesProps {
  principles: Array<{
    title: string
    description: string
    icon: string
    iconHeight?: string
  }>
}

const iconSelector = (type: string, color: string, height?: string) => {
  if (type === "family") {
    return <IconFamily color={color} height={height} />
  }
  if (type === "respitealt") {
    return <IconRespiteAlt color={color} height={height} />
  }
  if (type === "mentalhealth") {
    return <IconMentalHealth color={color} height={height} />
  }
  if (type === "fostercare") {
    return <IconFosterCare color={color} height={height} />
  }
  if (type === "maintenance") {
    return <IconMaintenance color={color} height={height} />
  }
  if (type === "kinship") {
    return <IconKinship color={color} height={height} />
  }
  return undefined
}

const Principles = withTheme(
  ({ principles, theme }: PrinciplesProps & ThemeProps<Interfaces.Theme>) => (
    <div className="flex flex-wrap pv4-ns">
      {principles.map(({ title, description, icon, iconHeight }) => (
        <div key={title} className="w-100 w-50-m w-33-l ph4-ns pv4 tc">
          <IconWrapper className="center" size="125" color="#fff">
            {iconSelector(icon, theme.medium, iconHeight ? iconHeight : "58")}
          </IconWrapper>
          <h3 className="ts-display-4 fw8 mt3">{title}</h3>
          <p>{description}</p>
        </div>
      ))}
    </div>
  )
)
const CareCreatingConditionsForChange = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    careModelFactsheet
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`CARE: Creating Conditions for Change | ${siteMetadata.title}`}
        description="We are committed to ensuring carers and staff support children in a way that creates conditions for positive change."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout theme={youthFull}>
        <Banner
          title="CARE"
          subTitle="We are committed to ensuring carers and staff support children in a way that creates conditions for positive change."
          image={bannerImage}
          breadcrumbs={[
            routesObject.home,
            {
              to: "/",
              text: "Our approach",
              title: "Return to parent page"
            },
            {
              to: "..",
              text: "Child, youth and family",
              title: "Return to main page"
            }
          ]}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>
                Creating conditions for positive change
              </Heading>
              <p>
                Supporting children and families in a way that creates
                conditions for positive change, Life Without Barriers uses the
                CARE model, which was developed at the Residential Child Care
                Project at Cornell University. Guiding our work with children
                and families, this evidence-based, trauma-informed model:
              </p>
              <ul>
                <li className="mb2">
                  Influences the way we think about children. It focuses on our
                  relationships to help children build new competencies and
                  guides our interactions with children and their families.
                </li>
                <li className="mb2">
                  Helps us make decisions and take actions based on the best
                  interests of children.
                </li>
                <li className="mb2">
                  Emphasises that everybody does well if they can and by working
                  together we can create conditions for positive changes in the
                  lives of children.
                </li>
                <li className="mb2">
                  Gives us a shared language and understanding so we can be
                  consistent in our practice and work towards a common goal.
                </li>
              </ul>
              <ReadMoreLink
                text="Implement the CARE model in your agency"
                href={careModelFactsheet.publicURL}
              />
            </Box>
            <Box className="w-third-l mt4 mt0-l">
              <Tile ribbon>
                <div className="pa3 pa4-ns">
                  <h3 className="ts-display-4 fw6 color-lwb-theme-medium">
                    For more information
                  </h3>
                  <p className="copy mt3 mb4">
                    For more information about implementing the CARE model in
                    your agency, contact:
                  </p>
                  <ContactUsEmailLink
                    text="stride@lwb.org.au"
                    link="mailto:stride@lwb.org.au"
                  />
                </div>
              </Tile>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box>
                <Heading size={2} className="tc">
                  The six CARE principles
                </Heading>
                <p className="tc ph5-ns">
                  There are 6 CARE principles we use to guide our work with
                  children, young people and families. They help us understand
                  the impact of trauma on a child&apos;s development and help us
                  teach children skills in a developmentally appropriate way.
                  Our carers apply this model in the way they look after, teach,
                  guide and support children.
                </p>
                <Principles
                  principles={[
                    {
                      icon: "family",
                      title: "Relationship based",
                      description:
                        "Children need trusting, meaningful relationships with the adults who care for them so they build emotional and social confidence for other aspects of their lives."
                    },
                    {
                      icon: "respitealt",
                      title: "Developmentally Focused",
                      description:
                        "Strategies for change are more effective when they are matched to where the child is at. We need to use strategies that challenge but do not overwhelm children."
                    },
                    {
                      icon: "mentalhealth",
                      title: "Trauma Informed",
                      description:
                        "Always consider the impact trauma can have on a child’s development and modify our expectations and interactions accordingly."
                    },
                    {
                      icon: "fostercare",
                      title: "Family Involved",
                      description:
                        "A child’s identity is tied to their family, community and culture. Involving family and supporting cultural connections is a vital component of wellbeing and permanency."
                    },
                    {
                      icon: "maintenance",
                      title: "Competency Centred",
                      description:
                        "Children need to be helped to develop the skills, knowledge and attitudes they need to negotiate everyday life."
                    },
                    {
                      icon: "kinship",
                      title: "Ecologically Oriented",
                      description:
                        "Children need a caring and supportive environment with helpful models showing them how to care for themselves and others."
                    }
                  ]}
                />
                <p className="tc ph5-ns bt bn-ns pv4 pv0-ns b-lwb-pink">
                  CARE is an evidence-based, trauma-informed model developed by
                  the Residential Child Care Project at Cornell University.
                </p>
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/img-care-banner.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    careModelFactsheet: file(
      name: { eq: "CARE-Corporate-Brochure-March-2022" }
    ) {
      name
      publicURL
    }
  }
`

export default CareCreatingConditionsForChange
